import { useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { conf } from "../../utils/config";
import { useSelector } from "react-redux";
import { selectToken, setToken } from "../../reduxSlices/studentSlice";
import { useAppDispatch } from "../../app/store";
import { selectParameter } from "../../reduxSlices/parametersSlice";
import { AppState } from "../../enums/appState.enum";

export enum ServerStatus {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  InternalServerError = 500,
}

export default function AxiosManager() {
  const token = useSelector(selectToken);
  const dispatch = useAppDispatch();
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const defaultConfig: AxiosRequestConfig = {
    baseURL: conf("BASE_URL"),
  };
  const { instance } = useMsal();
  const { started, appState } = useSelector(selectParameter);

  // Evitamos múltiples ejecuciones concurrentes de logout.
  const isLoggingOut = useRef(false);

  const handleLogout = async (state: string) => {
    if (isLoggingOut.current) return;
    isLoggingOut.current = true;
    try {
      // Limpiamos storages y reseteamos el token en redux
      localStorage.clear();
      //sessionStorage.clear();
      dispatch(setToken(""));
      if (state !== AppState.BY_PASS) {
        await instance.logoutRedirect({
          postLogoutRedirectUri: redirectUri,
        });
      }
    } finally {
      isLoggingOut.current = false;
    }
  };

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        // console.log("Manager REQUEST ==> ", config);
        config.baseURL = defaultConfig.baseURL;

        const storedToken = localStorage.getItem("token");
        if (storedToken === "x") {
          handleLogout(appState);
        }

        const authToken = token?.length > 0 ? token : storedToken;
        if (authToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          } as AxiosRequestHeaders;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    let responseInterceptor: number | null = null;
    if (started) {
      responseInterceptor = axios.interceptors.response.use(
        (response) => {
          // console.log("Manager RESPONSE ==> ", response);
          if (response.status === ServerStatus.Unauthorized) {
            handleLogout(appState);
          }
          return response;
        },
        (error: AxiosError) => {
          if (error.response?.status === ServerStatus.Unauthorized) {
            handleLogout(appState);
          }
          return Promise.reject(error);
        }
      );
    }

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      if (responseInterceptor !== null) {
        axios.interceptors.response.eject(responseInterceptor);
      }
    };
  }, [token, appState, started]); // Se actualiza cuando cambian token, appState o started

  return null;
}