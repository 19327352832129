import { useSelector } from "react-redux";
import { selectStudent } from "../../reduxSlices/studentSlice";
import "./BlockedScreen.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

export default function BlockedScreen() {
  const student = useSelector(selectStudent);

  return (
    <div className="blocked-screen-container">
      <div className="blocked-alert-box flex-col md:flex-row">
        <SlIcon className="blocked-alert-icon" name="exclamation-octagon"></SlIcon>
        <div className="">
          <h2>El acceso a la plataforma se encuentra bloqueado.</h2>
          <p>
            {student.bloqueoMensaje ? student.bloqueoMensaje : "Consulte con el departamente de Alumnos su situación."}
          </p>
          {student.bloqueoSubmensaje && (
            <div className="blocked-contactos">
              <p>{student.bloqueoSubmensaje}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
