import "./LoginPage.css";
import { useSelector } from "react-redux";
import Login from "../../components/Login/Login";
import { selectParameter } from "../../reduxSlices/parametersSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { selectStudent } from "../../reduxSlices/studentSlice";

const LoginPage = () => {
  // TODO
  const { started, appState } = useSelector(selectParameter);
  const navigate = useNavigate();
  const student = useSelector(selectStudent);

  // useEffect(() => {
  //   const logged = student.email;
  //   if (!logged) {
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     navigate("/login");
  //   }
  // }, [student.email]);

  return (
    <>
      {started ? (
        <Login />
      ) : (
        <>
          {/* <div className="app-init-loading">
    <div className="div-1 animated-div"></div>
    <div className="div-2 animated-div"></div>
    <div className="div-3 animated-div"></div>
  </div> */}
        </>
      )}
    </>
  );
};

export default LoginPage;
