import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface StudentState {
  id: number;
  email: string;
  completeName: { name: string; lastName: string };
  studentInitState: number;
  bloqueoActivo: boolean;
  bloqueoMensaje: string
  bloqueoSubmensaje: string;
  prioridad: boolean | null;
  mensajePrioridad: string;
  actualizandoPrioridad: boolean;
  mostrarHorarios: boolean;
  carreraActual: string;
  listadoCarreras: [];
  token: string;
}

const initialState: StudentState = {
  id: 0,
  email: "",
  completeName: { name: "", lastName: "" },
  studentInitState: 0,
  bloqueoActivo: false,
  bloqueoMensaje: "",
  bloqueoSubmensaje: "",
  prioridad: null,
  mensajePrioridad: "Revisando prioridad.",
  mostrarHorarios: true,
  actualizandoPrioridad: false,
  carreraActual: "",
  listadoCarreras: [],
  token: "",
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    updateStudentData: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.completeName = action.payload.completeName;
    },
    setStudentInicializado: (state, action) => {
      state.studentInitState = action.payload;
    },
    setStudentBloqueoActivo: (state, action) => {
      state.bloqueoActivo = action.payload;
    },
    setStudentBloqueoMensajes: (state, action) => {
      state.bloqueoMensaje = action.payload.mensaje;
      state.bloqueoSubmensaje = action.payload.subMensaje;
    },
    setStudentPrioridad: (state, action) => {
      if (typeof action.payload.prioridad === "boolean") {
        state.prioridad = action.payload.prioridad;
      }
      state.mensajePrioridad = action.payload.mensajePrioridad;
      state.actualizandoPrioridad = !!action.payload.actualizandoPrioridad;
    },
    setStudentMostrarHorarios: (state, { payload }) => {
      state.mostrarHorarios = payload;
    },
    setCarrearActual: (state, { payload }) => {
      state.carreraActual = payload.trim();
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setListadoCarreras: (state, { payload }) => {
      state.listadoCarreras = payload;
    },
  },
  extraReducers(builder) {},
});

export const selectStudent = (state: RootState) => state.student;
export const selectToken = (state: RootState) => state.student.token;
export const selectListadoCarreras = (state: RootState) => state.student.listadoCarreras;

export const {
  updateStudentData,
  setStudentInicializado,
  setStudentBloqueoActivo,
  setStudentBloqueoMensajes,
  setStudentPrioridad,
  setStudentMostrarHorarios,
  setCarrearActual,
  setToken,
  setListadoCarreras,
} = studentSlice.actions;

export default studentSlice.reducer;
