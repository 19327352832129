import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { IOfferedSubjectData } from "../types";

interface ModalsOverlays {
  inTransitInscriptionRequet: boolean;
  panelInfoSubjectOffered: boolean;
  panelInfoSubjectInscribed: boolean;
  panelFilters: boolean;
  mobileFeatures: boolean;
  collapseCards: boolean;
  collapseCardsSinCupo: boolean;
  lastCalendarAdd: string;
  desktopTab: "calendario" | "materias";
  sectionToChange: { id: number; idMateria: number } | null;
  confirmChangeSectionData: IOfferedSubjectData | null;
  bannerPrioridadVisible: boolean;
  lastOfertaUpdateTime: number | null;
  invalidarSemaforoSocketCount: number;
  isMobileSearchActive: boolean;
  isLogginProcess: boolean;
}


const initialState: ModalsOverlays = {
  inTransitInscriptionRequet: false,
  panelInfoSubjectOffered: false,
  panelInfoSubjectInscribed: false,
  panelFilters: false,
  mobileFeatures: false,
  collapseCards: false,
  collapseCardsSinCupo: false,
  lastCalendarAdd: "",
  desktopTab: "materias",
  sectionToChange: null,
  confirmChangeSectionData: null,
  bannerPrioridadVisible: true,
  lastOfertaUpdateTime: null,
  invalidarSemaforoSocketCount: 0,
  isMobileSearchActive:false,
  isLogginProcess:false
};


export const globalFlagsSlice = createSlice({
  name: "globalFlags",
  initialState,
  reducers: {
    setInTransitInscriptionRequest: (state, action) => {
      state.inTransitInscriptionRequet = action.payload;
    },
    setPanelSubjectOffered: (state, action) => {
      state.panelInfoSubjectOffered = action.payload;
    },
    setPanelSubjectInscribed: (state, action) => {
      state.panelInfoSubjectInscribed = action.payload;
    },
    setPanelFilters: (state, action) => {
      state.panelFilters = action.payload;
    },
    setMobileFeatures: (state, action) => {
      state.mobileFeatures = action.payload;
    },
    setCardsCollapse: (state, action) => {
      if (action.payload === "all") {
        state.collapseCards = true;
        state.collapseCardsSinCupo = false;
      } else if (action.payload === "sinCupo") {
        state.collapseCardsSinCupo = true;
        state.collapseCards = false;
      } else if (action.payload === "none") {
        state.collapseCardsSinCupo = false;
        state.collapseCards = false;
      }
    },
    setLastCalendarAdd: (state, action) => {
      state.lastCalendarAdd = action.payload;
    },
    setDesktopTab: (state, action) => {
      state.desktopTab = action.payload;
    },
    setSectionToChange: (state, action) => {
      state.sectionToChange = action.payload;
    },
    setConfirmChangeSectionData: (state, action) => {
      state.confirmChangeSectionData = action.payload;
    },
    setBannerPrioridadVisible: (state, action) => {
      state.bannerPrioridadVisible = action.payload;
    },
    setLastUpdateOferta: (state, action) => {
      state.lastOfertaUpdateTime = action.payload;
    },
    setInvalidarSemaforoSocketCount: (state, action) => {
      state.invalidarSemaforoSocketCount = action.payload;
    },
    setIsMobileSearchActive: (state, action) => {
      state.isMobileSearchActive = action.payload;
    },
    setIsLogginProcess: (state, action) => {
      state.isLogginProcess = action.payload;
    },

  },
});

export const selectInTransitInRquest = (state: RootState) => state.globalFlags.inTransitInscriptionRequet;
export const selectPanelSubjectOffered = (state: RootState) => state.globalFlags.panelInfoSubjectOffered;
export const selectPanelSubjectInscribed = (state: RootState) => state.globalFlags.panelInfoSubjectInscribed;
export const selectPanelFilters = (state: RootState) => state.globalFlags.panelFilters;
export const selectMobileFeatures = (state: RootState) => state.globalFlags.mobileFeatures;
export const selectFlags = (state: RootState) => state.globalFlags;
export const selectInvalidarSemaforoSocketCount = (state: RootState) => state.globalFlags.invalidarSemaforoSocketCount;

export const {
  setInTransitInscriptionRequest,
  setPanelSubjectOffered,
  setPanelSubjectInscribed,
  setPanelFilters,
  setMobileFeatures,
  setCardsCollapse,
  setLastCalendarAdd,
  setDesktopTab,
  setSectionToChange,
  setConfirmChangeSectionData,
  setBannerPrioridadVisible,
  setLastUpdateOferta,
  setInvalidarSemaforoSocketCount,
  setIsMobileSearchActive,
  setIsLogginProcess
} = globalFlagsSlice.actions;

export default globalFlagsSlice.reducer;
