import { QuotaLevels } from "../enums/reservation.enum";
import { IOfferedSubjectByType, IOfferedSubjectData } from "../types";

export const normalizeText = (str: string) => {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

export const getQuotaColor = (quotaAvailability: number) => {
  switch (true) {
    case quotaAvailability === QuotaLevels.HIGH:
      return "green";
    case quotaAvailability === QuotaLevels.MID:
      return "orange";
    case quotaAvailability === QuotaLevels.LOW:
      return "red";
    case quotaAvailability === QuotaLevels.NONE:
      return "gray";
    default:
      return "gray";
  }
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseHours = (time: number) => {
  const hour = String(time).split("");
  const minutes = hour.splice(-2).join("");
  return `${hour.join("")}:${minutes}`;
};

export function createSubjectSlug(text: string) {
  if (!text) {
    return "";
  }

  let slug = "";
  if (text && text.includes("-")) {
    const nombre = text
      .split("-")[0]
      .split(" ")
      .filter((t) => t !== "");

    const seccion = text
      .split("-")[1]
      .split(" ")
      .filter((t) => t !== "")[0];

    slug = nombre.map((word) => word.charAt(0)).join("") + " " + seccion;
  } else {
    const nombre = text.split(" ").filter((t) => t !== "");
    slug = nombre.map((word) => word.charAt(0)).join("");
  }

  return slug;
}

export function copyTextToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

export const findProfeTitular = (subjectData: IOfferedSubjectData | undefined) => {
  if (!subjectData) return "A designar";
  let profe;
  for (const cursada of subjectData?.subjectsByType) {
    for (const horario of cursada?.subjectInfo) {
      const tipoClase = horario.values.tipoClase;
      if (horario.values.rol === "Profesor" && (tipoClase === "Teórica" || tipoClase === "Teórica-Práctica")) {
        profe = horario.values.profesores[0];
        if (profe) break;
      }
    }
    if (profe) break;
  }
  return profe ? profe : "A designar";
};

export function extraerNombreMateria (texto: string) {
  let indiceUltimoGuion = texto.lastIndexOf("-");
  if (indiceUltimoGuion === -1) return texto; // Si no hay "-", devuelve el texto original
  return texto.slice(0, indiceUltimoGuion).trim(); // Recorta y elimina espacios finales
};

export function extraerSeccion(texto: string) {
  const regex = /- (S\d+)/;
  const match = texto.match(regex);
  return match ? match[1] : null;
}
