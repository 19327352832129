import "./Pages.css";
import { useNavigate } from "react-router-dom";
import BlockedScreen from "../components/BlockedScreen/BlockedScreen";
import { useMsal } from "@azure/msal-react";
import { useAppDispatch } from "../app/store";


const BlockedPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const { instance } = useMsal();


  // if (!student.bloqueoActivo) {
  //   navigate("/login");
  // }  

  const handleGoLogin = () => {
    handleLogout()
  };  


  const handleLogout = async () => {
    localStorage.clear();
    sessionStorage.clear()
    await instance.logoutRedirect({
      postLogoutRedirectUri: redirectUri,
    });
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <BlockedScreen />
      <button onClick={handleGoLogin} className="back-to-login-btn">
        Volver al inicio
      </button>
    </div>
  );
};

export default BlockedPage;
