import { BsFillPinAngleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { getMateriasFijadasAlumno, toggleMateriaFijadaAlumno } from "../../Api/subject";
import { selectPinnedSubjectsList, setPinnedSubjectsList } from "../../reduxSlices/subjectsSlice";
import { IOfferedSubjectSection } from "../../types";
import { useAppDispatch } from "../../app/store";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useState } from "react";

interface BtnProps {
  subject: IOfferedSubjectSection;
}

export default function ButtonPin({ subject }: BtnProps) {
  const dispatch = useAppDispatch();
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);
  const student = useSelector(selectStudent);

  const [isLoading, setIsLoading] = useState(false)

  const pinned = pinnedSubjectsList.indexOf(subject.id) > -1;

  const handlePinSubject = async (idCurso: number, pinned: boolean, studentId:number) => {
    setIsLoading(true)
    const result = await toggleMateriaFijadaAlumno(studentId, idCurso);
    const fijadas = await getMateriasFijadasAlumno(studentId);

    if (fijadas.status === 200 && fijadas.data !== null) {
      dispatch(setPinnedSubjectsList(fijadas.data));
    } else {
      alert("Error al fijar materia");
    }
    setIsLoading(false)
  };

  return (
    <button
      className={`panel-drawer-actions ${pinned ? "btn-pin" : ""} ${isLoading?"animate-spin":""} disabled:opacity-50 disabled:cursor-not-allowed`}
      title="Fijar materia"
      onClick={() => handlePinSubject(subject.id, pinned, student.id)}
      disabled={isLoading}
    >
      <BsFillPinAngleFill />
    </button>
  );
}
