import { ISubjectQuotas } from "../reduxSlices/subjectsSlice";
import { IQuota } from "../types";

export const getCourseQuota = (subjectQuotas: ISubjectQuotas, idCurso: number): IQuota => {
  try {
    const keys = Object.keys(subjectQuotas);
    if (!keys.includes(idCurso.toString())) {
      throw new Error(`No quota found`);
    } else {
      const quota = subjectQuotas[idCurso];
      return quota;
    }
  } catch (error) {
    console.error(`Error getting course quota idCurso: ${idCurso}`);
    return { quotaId: -1, qty: 0, availability: 0 };
  }
};
