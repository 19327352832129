import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useEffect, useRef, useState } from "react";
import "./DesktopSelectedSubjects.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectInscribedSubjects,
  selectOfferedSubjects,
  setDetailSubjectOffered,
} from "../../reduxSlices/subjectsSlice";
import { IInscribedSubject } from "../../types";
import EmptyCourses from "../EmptyCourses/EmptyCourses";
import { selectFlags, setPanelFilters } from "../../reduxSlices/globalFlagsSlice";
import BtnUnsuscribeSubject from "../BtnUnsuscribeSubject/BtnUnsuscribeSubject";
import { extraerNombreMateria, extraerSeccion, findProfeTitular } from "../../utils";

const DesktopSelectedSubjects = () => {
  const dispatch = useDispatch();
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const offeredSubjects = useSelector(selectOfferedSubjects);
  const { lastOfertaUpdateTime } = useSelector(selectFlags);

  const containerRef = useRef<HTMLDivElement>(null);
  const [totalCreditos, setTotalcreditos] = useState(0);

  useEffect(() => {
    setTotalcreditos(inscribedSubjects?.reduce((acc, current) => acc + current.sections[0].creditos, 0) || 0);
    console.log("Componente: LISTA DE INSCRIPTAS -> Actualizado 🔄");
  }, [inscribedSubjects, lastOfertaUpdateTime]);
  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleShowSubjectoInfo = (subjectId: number, cursoId: number) => {
    dispatch(setPanelFilters(false));
    dispatch(setDetailSubjectOffered({ subjectId, cursoId }));
  };

  return (
    <div className="desktop-inscribed-subjects" ref={containerRef}>
      <div className="desktop-inscribed-subjects-list">
        {inscribedSubjects && inscribedSubjects?.length > 0 ? (
          <>
            {lastOfertaUpdateTime !== null &&
              inscribedSubjects.map((s, index) => {
                const isLastItem = inscribedSubjects.length === index + 1;
                const buscadoEnOferta = offeredSubjects
                  ?.find((subject) => Number(subject.id) === s.id)
                  ?.sections.find((section) => section.id === s.idCurso);
                const profe = findProfeTitular(buscadoEnOferta?.subjectData);
                const observacion = buscadoEnOferta?.obs || "";
                return (
                  <InscribedSubjectCard
                    key={s.title + s.idCurso}
                    s={s}
                    observacion={observacion}
                    isLastItem={isLastItem}
                    profe={profe}
                    handleShowSubjectoInfo={handleShowSubjectoInfo}
                  />
                );
              })}
            {totalCreditos > 0 && (
              <div className="total-creditos-abogacia p-[10px] mb-3">
                <p>Total de créditos:</p>
                <h6>{totalCreditos}</h6>
              </div>
            )}
          </>
        ) : (
          <EmptyCourses />
        )}
      </div>
    </div>
  );
};

interface InscribedCardProps {
  s: IInscribedSubject;
  isLastItem: boolean;
  handleShowSubjectoInfo: (id: number, idCurso: number) => void;
  profe: string;
  observacion: string;
}

function InscribedSubjectCard({ s, isLastItem, handleShowSubjectoInfo, profe, observacion }: InscribedCardProps) {
  const alertaHorarios = s.sections.find(
    (section) => section.subjectData.desde === null || section.subjectData.desde === null
  );
  return (
    <div className="desktop-item-wrapper p-[10px]" style={!isLastItem ? { borderBottom: "0.5px solid #eeeeee" } : {}}>
      <div className="desktop-inscribed-subject-item">
        <div className="desktop-selected-subjects-item-left">
          <h2
            className="desktop-selected-subject-item-left-title"
            onClick={() => handleShowSubjectoInfo(Number(s.id), Number(s.idCurso))}
          >
            <strong>{extraerNombreMateria(s.title)}</strong> - {extraerSeccion(s.title)}
          </h2>
          <p>{profe || "---"}</p>
        </div>
        <div className="desktop-selected-subject-item-right">
          <BtnUnsuscribeSubject subject={s} />
        </div>
      </div>
      {alertaHorarios && (
        <div className="flex">
          <SlIcon name="exclamation-triangle" style={{ fontSize: "21px", minWidth: "20px", width: "20px" }}></SlIcon>
          <p className="text-[#B7B7B7] pl-1">
            <small>Sin horario asignado.</small>
          </p>
        </div>
      )}
      <div className="selected-subject-obs">
        {observacion.length > 0 && (
          <>
            <span className="border-[1px] border-[#424242] min-w-[21px] min-h-[21px] rounded-full flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="3" height="13" viewBox="0 0 3 13" fill="none">
                <path
                  d="M1.74457 4.12639C2.36431 4.12639 2.8667 4.62879 2.8667 5.24852L2.8667 13.8112C2.8667 14.431 2.36431 14.9333 1.74457 14.9333V14.9333C1.12484 14.9333 0.622446 14.431 0.622446 13.8112L0.622446 5.24852C0.622447 4.62879 1.12484 4.12639 1.74457 4.12639V4.12639ZM1.74457 0.814363C2.35814 0.814363 2.8667 1.31175 2.8667 1.92532V1.92532C2.8667 2.53888 2.35814 3.03627 1.74457 3.03627V3.03627C1.13101 3.03627 0.622447 2.53888 0.622447 1.92532V1.92532C0.622447 1.31175 1.13101 0.814363 1.74457 0.814363V0.814363Z"
                  fill="#424242"
                />
              </svg>
            </span>

            {/* <SlIcon name="info-circle" style={{ fontSize: "21px", minWidth: "20px", width: "20px" }}></SlIcon> */}
            <p className="text-[#B7B7B7] pl-1">{observacion}</p>
          </>
        )}

        {s.sections[0].creditos > 0 && (
          <p className="subject-cant-creditos">
            Créditos: <span>{s.sections[0].creditos}</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default DesktopSelectedSubjects;
