import { useSelector } from "react-redux";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { addCalendarSubject, removeCalendarSubject, selectCalendarSubjectsList, setDetailSubjectOffered } from "../../reduxSlices/subjectsSlice";
import { ICalendarizedSubjects, IOfferedSubjectSection } from "../../types";
import { useAppDispatch } from "../../app/store";
import { selectMobileFeatures, setDesktopTab, setLastCalendarAdd } from "../../reduxSlices/globalFlagsSlice";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useNavigate } from "react-router-dom";

interface BtnProps {
    subject: IOfferedSubjectSection;
}

export default function ButtonCalendarize({ subject }: BtnProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  /* ------------------------------ global store ------------------------------ */
  const student = useSelector(selectStudent);
  const calendarSubsList = useSelector(selectCalendarSubjectsList);
  const mobileFeatures = useSelector(selectMobileFeatures);

  const calendarize = calendarSubsList.some((object: ICalendarizedSubjects) => object.idCurso === subject.id);
  const alertaHorarios = !subject.subjectData.subjectsByType.find((horario) => horario.idHorario === null);

  const handleToggleCalendarSubject = async (idCurso: number, idMateria: number) => {
      const exist = calendarSubsList.some((object: ICalendarizedSubjects) => object.idCurso === idCurso);
      if (exist) {
        dispatch(removeCalendarSubject(idCurso));
        return;
      }
      dispatch(addCalendarSubject({ idCurso, idMateria }));
      dispatch(setLastCalendarAdd(String(idCurso))); // creado para hacer el scroll into view de la tarjeta
      // if MobileCalendario, go to calendar
      if (mobileFeatures) {
        dispatch(setDetailSubjectOffered(null));
        navigate("/calendario");
      } else {
        dispatch(setDesktopTab("calendario"));
      }
    };


  if (!student.mostrarHorarios) {
    return null;
  }

  return (
    <button
      className={`panel-drawer-actions ${
        calendarize ? "btn-calendar" : ""
      } disabled:opacity-30 disabled:cursor-not-allowed`}
      title="Visualizar en calendario"
      onClick={() => handleToggleCalendarSubject(Number(subject.id), Number(subject.idMateria))}
      disabled={!alertaHorarios}
    >
      <SlIcon name="calendar"></SlIcon>
    </button>
  );
}
