import { useDispatch, useSelector } from "react-redux";
import {
  selectFilterTagsList,
  selectInscribedSubjects,
  selectOfferedSubjects,
  selectPinnedSubjectsList,
  selectSearchSubjectsValue,
  selectSelectedFilterTagsList,
  selectSubjectsQuotas,
  setSelectedFilterTagsList,
} from "../../reduxSlices/subjectsSlice";
import OfferSubjectsVirtualizedList from "../OfferSubjectsVirtualizedList/OfferSubjectsVirtualizedList";
import { useEffect, useRef, useState } from "react";
import { VariableSizeList as List } from "react-window";
import { IInscribedSubject, IOfferedSubject } from "../../types";
import DesktopTab from "../DesktopTab/DesktopTab";
import SearchInput from "../SearchInput/SearchInput";
import { NavOptions } from "../NavOptions/NavOptions";
import { selectMobileFeatures, setCardsCollapse, setPanelFilters } from "../../reduxSlices/globalFlagsSlice";
import { normalizeText } from "../../utils";
import DesktopSubjectItem from "../DesktopSubjectItem/DesktopSubjectItem";
import { BsFillPinAngleFill } from "react-icons/bs";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlSkeleton from "@shoelace-style/shoelace/dist/react/skeleton";
import { getCourseQuota } from "../../utils/quota";

const filterSubjects = (subjects: IOfferedSubject[], searchTerm: string): IOfferedSubject[] => {
  const filteredSubjects = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        const matchesTitle = normalizeText(section.section).includes(normalizeText(searchTerm));
        const matchesProf = section.subjectData.subjectsByType.some((subject) => {
          return subject.subjectInfo.some((info) => {
            return info.values.profesores.some((prof) => {
              return normalizeText(prof).includes(normalizeText(searchTerm));
            });
          });
        });
        return matchesTitle || matchesProf;
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);
  return filteredSubjects;
};

const filterSubjectsByTag = (subjects: IOfferedSubject[], filterTagsList: string[]) => {
  if (!filterTagsList.length) {
    return subjects;
  }
  const subjectsByFilter = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        // Verificar que 'tag' esté definido y sea una cadena
        if (typeof section.tag !== "string") {
          return false;
        }
        // Dividir la cadena 'tag' y limpiar los espacios
        const tags = section.tag.split(",").map((item) => item.trim());
        // Verificar si algún elemento de 'tags' está en 'filterTagsList'
        // console.log(tags.some((item) => filterTagsList.includes(item)));
        return tags.some((item) => filterTagsList.includes(item));
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);

  return subjectsByFilter;
};

export default function OfferVirtualListContainer() {
  const dispatch = useDispatch();
  const offeredSubjects = useSelector(selectOfferedSubjects);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);
  const selectedFilterTagsListStore = useSelector(selectSelectedFilterTagsList);
  const searchSubjectValue = useSelector(selectSearchSubjectsValue);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const filterTagsList = useSelector(selectFilterTagsList);
  const mobileFeatures = useSelector(selectMobileFeatures);
  

  const virtualListRef = useRef<List>(null);
  const scrollTargetRef = useRef<HTMLDivElement>(null);

  const [filteredSubjects, setFilteredSubjects] = useState<IOfferedSubject[]>([]);
  const [subjectsInSearch, setSubjectsInSearch] = useState<IOfferedSubject[]>([]);
  const [filtro, setFiltro] = useState("OBLIGATORIO");
  const [verFijadas, setVerFijadas] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPositionVirtualList, setScrollPositionVirtualList] = useState(0);

  const countPinnedNotInscribed = (inscribedSubjects: IInscribedSubject[]|null, pinnedList:number[]) => {
    let count = 0;
    const inscribedSubsIds = inscribedSubjects ? inscribedSubjects?.map((s) => s.idCurso) : [];
    pinnedList.forEach((pinned) => {
        if (inscribedSubsIds.indexOf(pinned) === -1) {
          count++;
        }
    });
    return count;
  };
  
  const pinnedNotFilterCount = countPinnedNotInscribed(inscribedSubjects,pinnedSubjectsList)

  useEffect(()=>{
    if(pinnedNotFilterCount===0){
      
      setVerFijadas(false)
    }
  },[pinnedNotFilterCount])

  useEffect(() => {
    if (offeredSubjects && offeredSubjects.length >= 0) {
      const result = offeredSubjects.filter((s) => s.tipoMateria === filtro);
      setFilteredSubjects(result);
    } else {
    }
  }, [offeredSubjects, filtro]);


  /* -------------------------------- Busqueda + Filtros---------------------- */
  useEffect(() => {
    if (filteredSubjects && searchSubjectValue.trim()?.length > 0) {
      const newSubjects = filterSubjects(filteredSubjects, searchSubjectValue);
      const newSubjectsWithFilter = filterSubjectsByTag(newSubjects, selectedFilterTagsListStore || []);
      setSubjectsInSearch(newSubjectsWithFilter);
    } else if (filteredSubjects) {
      const newSubjects = filterSubjectsByTag(filteredSubjects, selectedFilterTagsListStore || []);
      setSubjectsInSearch(newSubjects);
    }
  }, [searchSubjectValue, selectedFilterTagsListStore, filteredSubjects]);

  /* --------------------------------- handler -------------------------------- */

  const handleSelectTab = (tipo: string) => {
    if (tipo === "OBLIGATORIO") {
      dispatch(setSelectedFilterTagsList([]));
      dispatch(setPanelFilters(false));
    }
    if (tipo) {
      setFiltro(tipo);
    } else {
      setFiltro("OBLIGATORIO");
    }
  };

  const handleScrollVirtualList = (data: any) => {
    setScrollPositionVirtualList(Number(data.scrollOffset));
  };

  const handleScroll = () => {
    setScrollPosition(Number(scrollTargetRef.current?.scrollTop));
  };

  const scrollVirtualListToTop = () => {
    if (virtualListRef.current) {
      virtualListRef.current.scrollTo(0);
    }
  };

    const handleOpenFilter = () => {
      dispatch(setPanelFilters(true));
    };

  const renderPinnedSubjects = (subjects: IOfferedSubject[]) => {
    const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
    return (
      <>
        {subjects.map((s) => {
          const pinnedSections = s.sections.filter(
            (curso) => pinnedSubjectsList.indexOf(curso.id) > -1 && inscribedSubsIds.indexOf(curso.id) === -1
          );
          const itemsList = pinnedSections.map((s) => {
            return (
              <DesktopSubjectItem
                idMateria={s.idMateria}
                id={s.id}
                key={s.id}
                subjectId={s.idMateria}
                section={s.section}
                subjectData={s.subjectData}
                quota={getCourseQuota(subjectsQuotas, s.id)}
                pinned={pinnedSubjectsList.indexOf(s.id) > -1}
                creditos={s.creditos}
                sectionType={s.tipoMateria}
                turno={s.turno}
                subject={s}
              />
            );
          });

          return itemsList;
        })}
      </>
    );
  };

  const css = `
  .skeleton sl-skeleton{
    --border-radius: 8px;
  }
`;

  return (
    <div className="flex-grow flex flex-col overflow-hidden">
      <div id="titulos" className="flex items-center justify-between mb-[10px] min-h-[46px] h-[46px]">
        {verFijadas ? (
          <h2 className="text-[#908C8C] font-bold text-[20px] flex items-center">
            {/* <BsFillPinAngleFill className="mr-2 text-[20px] text-[#00998C]" /> */}
            Cursos fijados:
            <span className="text-[#00C4B3] ml-2">{pinnedNotFilterCount}</span>
          </h2>
        ) : (
          <h2 className="text-[#908C8C] font-bold text-[20px] flex items-center">Oferta de cursos</h2>
        )}
        {verFijadas ? (
          <h2
            className="text-[#908C8C] text-[18px] cursor-pointer hover:underline"
            onClick={() => setVerFijadas(false)}
            style={mobileFeatures ? { fontSize: "16px" } : {}}
          >
            Ver todos
          </h2>
        ) : (
          <h2
            className={`${
              pinnedNotFilterCount > 0 ? "bg-[#00C4B3] border-none cursor-pointer text-[#fff]" : ""
            } text-[#908C8C] text-[18px] border-[1px] rounded-full px-3 py-1 flex items-center`}
            onClick={() => {
              if (pinnedNotFilterCount > 0) {
                setVerFijadas(!verFijadas);
                dispatch(setCardsCollapse("none"));
              }
            }}
          >
            {mobileFeatures ? (
              <BsFillPinAngleFill
                className={`mr-2 text-[20px] ${pinnedNotFilterCount > 0 ? "text-[#fff]" : "text-[#908C8C]"} `}
              />
            ) : (
              "Cursos fijados:"
            )}
            <span className="font-semibold ml-1">{pinnedNotFilterCount}</span>
          </h2>
        )}
      </div>

      {!verFijadas && (
        <div className="desktop-filters-nav relative">
          <DesktopTab filtro={filtro} handleSelectTab={handleSelectTab} />
          <div className="search-and-filter">
            <SearchInput />
            {filtro !== "OBLIGATORIO" && filterTagsList && filterTagsList?.length > 0 && (
              <button
                onClick={handleOpenFilter}
                className={`filters-btn ${selectedFilterTagsListStore?.length ? "filters-btn-active" : ""}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                  <path
                    d="M14.9814 0H1.01854C0.159386 0 -0.31238 1.04052 0.233792 1.73083L5.9521 8.95821C6.10199 9.14766 6.18396 9.38545 6.18396 9.63088V15.7943C6.18396 16.1287 6.33583 16.4434 6.59355 16.6431L8.07226 17.7887C8.7429 18.3083 9.69589 17.8101 9.69589 16.9399V9.63676C9.69589 9.38776 9.78025 9.14674 9.93414 8.95609L15.7598 1.73882C16.3155 1.05041 15.8453 0 14.9814 0Z"
                    fill={`${selectedFilterTagsListStore?.length ? "white" : "#424242"}`}
                  />
                </svg>
              </button>
            )}
            <NavOptions />
          </div>
        </div>
      )}

      {verFijadas && pinnedSubjectsList.length > 0 ? (
        <div className="w-full flex flex-col overflow-y-scroll">{renderPinnedSubjects(offeredSubjects || [])}</div>
      ) : (
        <div className="flex-grow flex flex-col overflow-hidden" onScroll={handleScroll} ref={scrollTargetRef}>
          {scrollPositionVirtualList > 100 && (
            <SlIcon
              name="box-arrow-in-up"
              onClick={scrollVirtualListToTop}
              className="z-10 p-2 right-[10px] bottom-[10px] bg-[#656464] rounded-full absolute cursor-pointer text-white"
              style={mobileFeatures ? { bottom: "80px" } : {}}
            ></SlIcon>
          )}
          {offeredSubjects === null && (
            <div className="flex flex-col gap-4 skeleton">
              <SlSkeleton effect="pulse" className="h-[200px]" />
              <SlSkeleton effect="pulse" className="h-[200px]" />
              <SlSkeleton effect="pulse" className="h-[200px]" />
              <style>{css}</style>
            </div>
          )}
          <OfferSubjectsVirtualizedList
            subjects={subjectsInSearch}
            virtualListRef={virtualListRef}
            handleScroll={handleScrollVirtualList}
          />
        </div>
      )}
      {/* </div> */}
    </div>
  );
}
