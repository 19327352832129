import React, { useEffect } from "react";
import "./Layout.css";
import { useLocation, useNavigate } from "react-router-dom";
import TransitionModal from "../TransitionModal/TransitionModal";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import { EstadoAlumnoCarrera } from "../../enums/alumnoCarrera.enum";
import ModeHeader from "../ModeHeader/ModeHeader";
import DesktopHeader from "../DesktopHeader/DesktopHeader";
import BlockedScreen from "../BlockedScreen/BlockedScreen";
import { selectInTransitInRquest, selectPanelFilters, setMobileFeatures } from "../../reduxSlices/globalFlagsSlice";
import ModalOverlay from "../ModalOverlay/ModalOverlay";
import PanelDrawer from "../PanelDrawer/PanelDrawer";
import { selectDetailSubjectOffered } from "../../reduxSlices/subjectsSlice";
import PanelFilters from "../PanelFilters/PanelFilters";
import { selectParameter } from "../../reduxSlices/parametersSlice";
import BannerPrioridad from "../BannerPrioridad/BannerPrioridad";
import MobileNav from "../MobileNav/MobileNav";
import MobileMenu from "../MobileMenu/MobileMenu";
import { useAppDispatch } from "../../app/store";
import BannerConsultaPrioridad from "../BannerConsultaPrioridad/BannerConsultaPrioridad";
import BannersContainer from "../BannersContainer/BannersContainer";

interface IPropsLayout {
  children: React.ReactNode;
  sessionExpired: boolean;
}

const MobileLayout = ({ children, sessionExpired }: IPropsLayout) => {
  const dispatch = useAppDispatch();
  const student = useSelector(selectStudent);
  const { serverError, appState } = useSelector(selectParameter);
  const detailSubjectOffered = useSelector(selectDetailSubjectOffered);
  const panelFilters = useSelector(selectPanelFilters);

  const location = useLocation();
  const navigate = useNavigate();
  const micrsoftSession = sessionStorage.getItem("msal.account.keys"); 
  
  useEffect(() => {
    // set ON mobile features
    dispatch(setMobileFeatures(true));
    
    if (student.bloqueoActivo) {      
      navigate("/bloqueo");
    }
    if (serverError) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/error");
    }
    if (sessionExpired ) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }
    if (location.pathname === "/" && !micrsoftSession && (appState === "0" || appState === "4") && localStorage.getItem("token") === null) {
      localStorage.clear();
      navigate("/login");
    }
  }, [serverError, sessionExpired,student.bloqueoActivo]);

  return (
    <div className="mobile-layout">
      <ModeHeader />
      {/* ------------------------------- HEADER Menu ------------------------------ */}
      {!location.pathname.includes("login") &&
        location.pathname !== "/bloqueo" &&
        location.pathname !== "/usuario" &&
        location.pathname !== "/error" && <DesktopHeader />}
      {/* -------------------------------- CHILDREN -------------------------------- */}
      <div className="mobile-layout-content">
        {!location.pathname.includes("login") && location.pathname !== "/bloqueo" && (
          <>
            <MobileNav />
            <BannersContainer />
            <MobileMenu />
            {/* flota por encima, no importa su ubicacion */}
          </>
        )}
        {children}
      </div>
      {/* -------------------------------------------------------------------------- */
      /*                                   MODALES                                  */
      /* -------------------------------------------------------------------------- */}
      {/* ------------------------------ MODAL General ----------------------------- */}
      {(student.id === 0 || student.email === "") &&
        !student.bloqueoActivo &&
        (location.pathname === "/" || location.pathname === "/mis-cursos" || location.pathname === "/calendario") && (
          <TransitionModal open={true} text={"logoAnimado"} />
        )}
      {/* --------------------------- MODAL Inicializando -------------------------- */}
      {student.studentInitState === EstadoAlumnoCarrera.INICIALIZACION_KV_PENDIENTE && (
        <TransitionModal
          open={true}
          text={"Inicializando la información del alumno. Esta operación puede demorar unos minutos."}
        />
      )}
      {/* --------------------------- Overlay espera -------------------------- */}
      {/* {inTransitInscriptionRequet && <ModalOverlay text={""} />} */}
      {/* ----------------------- Panel Drawe Subject Detail ----------------------- */}
      {location.pathname !== "/login" && detailSubjectOffered && <PanelDrawer subject={detailSubjectOffered} />}
      {location.pathname !== "/login" && panelFilters && <PanelFilters />}
    </div>
  );
};

export default MobileLayout;

// function mapToNewFormat(original: IInscribedSubject): IOfferedSubjectSection {
//   const mappedSubjectInfo = original.sections.map((section, index) => {
//     return {
//       id: 1000 + index, // Asignar ID arbitrario o generar uno
//       title: "Horario",
//       values: {
//         tipoClase: section.subjectData.tipoCursada,
//         dia: section.subjectData.dia,
//         desde: parseInt(section.subjectData.desde, 10),
//         hasta: parseInt(section.subjectData.hasta, 10),
//         rol: "profesor",
//         profesores: [section.subjectData.profesor],
//       },
//     };
//   });
//   const mappedSubjectsByType = mappedSubjectInfo.map((item) => {
//     return {
//       idSection: item.id,
//       idHorario: item.id,
//       subjectType: item.values.tipoClase,
//       subjectInfo: [item],
//     };
//   });

//   let result = {
//     id: original.idCurso,
//     idMateria: original.id,
//     section: original.title,
//     subjectData: {
//       //@ts-ignore
//       subjectsByType: mappedSubjectsByType,
//       subjectData: {
//         idA: 0,
//         idC: 0,
//         idS: 0,
//         tic: "0",
//         tim: "0",
//       },
//       reserveData: {
//         idA: 0, // Valor predeterminado
//         idS: original.idCurso, // Usando el ID del curso
//         idC: 0, // Valor predeterminado
//         tic: "NORMAL", // Valor predeterminado
//         tim: original.tipoMateria, // Usando el tipo de materia
//       },
//     },
//     tipoMateria: original.tipoMateria,
//     title: original.title,
//     obs: "", // Valor predeterminado
//     sectionNumber: Number(original.sections[0].sectionNumber),
//     year: original.sections[0].year,
//     catalogo: original.sections[0].catalogo,
//     link: original.sections[0].link,
//     tipoMinimo: original.sections[0].tipoMinimo,
//     //@ts-ignore
//     creditos: parseFloat(original.sections[0].creditos) || 0, // Convertir a número
//     tag: original.sections[0].tag,
//     correlativa: false, // Valor predeterminado
//     modalidad: "", // Valor predeterminado
//   };

//   //@ts-ignore
//   return result;
// }
