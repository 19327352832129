import { toast } from "react-toastify";
import { stripHtml } from "string-strip-html";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

interface TProps {
  msg: string;
  title: string;
}

const CustomToast = ({ msg, title }: TProps) => (
  <div>
    <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>{title}</h4>
    <p style={{ fontSize: "12px" }}>{msg}</p>
  </div>
);

export const useToastError = () => {
  const showError = (message: string, onClose?: () => void) => {
    const msg = stripHtml(message || "").result;
    toast(<CustomToast msg={msg} title={"¡Ups!"} />, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      onClose: onClose,
      style: {
        borderTop: "5px solid #E31616",
        fontSize: "12px",
        color: "#424242",
      },
      icon: (
        <div
          style={{
            background: "#E31616",
            color: "white",
            borderRadius: "100vh",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "22px",
          }}
        >
          <SlIcon
            style={{
              color: "white",
            }}
            name="x"
          ></SlIcon>
        </div>
      ),
    });
  };
  return showError;
};

export const useToastInfo = () => {
  const showInfo = (message: string, onClose?: () => void) => {
    const msg = stripHtml(message || "").result;
    toast(<CustomToast msg={msg} title="Info" />, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      onClose: onClose,
      style: {
        borderTop: "5px solid #00C4B3",
        fontSize: "12px",
        color: "#424242",
      },
      icon: (
        <div
          style={{
            background: "#00C4B3",
            color: "white",
            borderRadius: "100vh",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "22px",
          }}
        >
          <SlIcon
            style={{
              color: "white",
            }}
            name="info-lg"
          ></SlIcon>
        </div>
      ),
    });
  };
  return showInfo;
};

export const useToastSuccess = () => {
  const showSuccess = (message: string, title?: string, onClose?: () => void) => {
    const msg = stripHtml(message || "").result;
    toast(<CustomToast msg={msg} title={title || "¡Operación exitosa!"} />, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      onClose: onClose,
      style: {
        borderTop: "5px solid #00C4B3",
        fontSize: "12px",
        color: "#424242",
      },
      icon: (
        <div
          style={{
            background: "#00C4B3",
            color: "white",
            borderRadius: "100vh",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "22px",
          }}
        >
          <SlIcon
            style={{
              color: "white",
            }}
            name="check"
          ></SlIcon>
        </div>
      ),
    });
  };
  return showSuccess;
};

export const useToastWarning = () => {
  const showWarning = (message: string, onClose?: () => void) => {
    const msg = stripHtml(message || "").result;
    toast(<CustomToast msg={msg} title={"¡Atención!"} />, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      onClose: onClose,
      style: {
        borderTop: "5px solid #F5A800",
        fontSize: "12px",
        color: "#424242",
      },
      icon: (
        <div
          style={{
            background: "#F5A800",
            color: "white",
            borderRadius: "100vh",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "22px",
          }}
        >
          <SlIcon
            style={{
              color: "white",
            }}
            name="exclamation-lg"
          ></SlIcon>
        </div>
      ),
    });
  };
  return showWarning;
};

export const showToastError = (message: string, onClose?: () => void) => {
  const msg = stripHtml(message || "").result;
  toast(<CustomToast msg={msg} title={"¡Ups!"} />, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    onClose: onClose,
    style: {
      borderTop: "5px solid #E31616",
      fontSize: "12px",
      color: "#424242",
    },
    icon: (
      <div
        style={{
          background: "#E31616",
          color: "white",
          borderRadius: "100vh",
          width: "30px",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "22px",
        }}
      >
        <SlIcon
          style={{
            color: "white",
          }}
          name="x"
        ></SlIcon>
      </div>
    ),
  });
};

export const showToastWarning = (message: string, onClose?: () => void) => {
  const msg = stripHtml(message || "").result;
  toast(<CustomToast msg={msg} title={"¡Atención!"} />, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    onClose: onClose,
    style: {
      borderTop: "5px solid #F5A800",
      fontSize: "12px",
      color: "#424242",
    },
    icon: (
      <div
        style={{
          background: "#F5A800",
          color: "white",
          borderRadius: "100vh",
          width: "30px",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "22px",
        }}
      >
        <SlIcon
          style={{
            color: "white",
          }}
          name="exclamation-lg"
        ></SlIcon>
      </div>
    ),
  });
};
