import { IOfferedSubjectByType } from "../types";

type ProfesoresList = { id: number; nombre: string; tipoClase: string; cargo: string }[];

const ordenarProfesores = (profesores: ProfesoresList): ProfesoresList => {
  const ordenProfesores = ["Teórica", "Teórica-Práctica", "Práctica 1", "Práctica 2", "Práctica 3", "Apoyo"];
  return profesores.sort((a, b) => ordenProfesores.indexOf(a.tipoClase) - ordenProfesores.indexOf(b.tipoClase));
};

const orderHorariosPorTipoDeClase = (horarios: any[]) => {
  function comparaTipoClase(a: { titulo: string }, b: { titulo: string }) {
    const orden = ["Teórica", "Teórica-Práctica", "Práctica 1", "Práctica 2", "Práctica 3", "Apoyo"];
    const indiceA = orden.indexOf(a.titulo);
    const indiceB = orden.indexOf(b.titulo);
    return indiceA - indiceB;
  }
  return horarios.sort(comparaTipoClase);
};

export function transformHorariosData(subjectByType: IOfferedSubjectByType[]): { titulo: string; detalle: any[] }[] {
  const horarios: { titulo: string; detalles: any[] }[] = [];
  const profesoresList: ProfesoresList = [];

  subjectByType.forEach((section) => {
    const { subjectType, subjectInfo } = section;
    const titulo = subjectType;

    const detalles = subjectInfo.map((info) => {
      const { dia, desde, hasta, profesores, rol, profesorId, aula, tipoClase } = info.values;
      profesores.forEach((profesor: any) =>
        profesoresList.push({ id: profesorId, nombre: profesor, cargo: rol, tipoClase })
      );
      return { dia, desde, hasta, id: info.id, aula };
    });

    const detallesSinRepetidos = detalles.filter(
      (d: any, index: any, self: any[]) =>
        index === self.findIndex((c) => c.id === d.id && c.dia === d.dia && c.desde === d.desde)
    );
    if (titulo !== null) {
      horarios.push({ titulo, detalles: detallesSinRepetidos });
    }
  });

  const profesoresSinRepetidos = Array.from(new Map(profesoresList.map((obj) => [obj.id, obj])).values()); // Se eliminan profesores repetidos
  // console.log(`------ INFORMACION PARA EL CURSO: ${subjectByType[0].idSection} ------`);
  /* -------------------------------------------------------------------------- */
  /*                         ORDER PROFESORES Y HORARIOS                        */
  /* -------------------------------------------------------------------------- */
  const profesoresOrdenados = ordenarProfesores(profesoresSinRepetidos);
  const horariosOrdenados = orderHorariosPorTipoDeClase(horarios);
  /* -------------------------------------------------------------------------- */
  horariosOrdenados.unshift({ titulo: "Profesor", detalles: profesoresOrdenados });

  const parsedResult: any[] = [];

  horariosOrdenados.forEach((item: any) => {
    if (item.titulo === "Profesor") {
      parsedResult.push({
        titulo: "Profesor",
        detalles: profesoresOrdenados.map((profesor) => {
          return { nombre: profesor.nombre, rol: profesor.cargo, cargo: profesor.cargo, tipoClase: profesor.tipoClase };
        }),
      });
    } else {
      parsedResult.push(item);
    }
  });

  //console.log("parsedResult:", parsedResult);
  //console.log("--------------------------------");
  return parsedResult;
}
