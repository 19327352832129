import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal/Modal";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../app/store";
import { selectFlags, setConfirmChangeSectionData, setSectionToChange } from "../reduxSlices/globalFlagsSlice";
import { addSubProcessSectionIds, removeSubProcessSectionIds, selectInscribedSubjects } from "../reduxSlices/subjectsSlice";
import { IInscribedSubject } from "../types";
import { changeSection } from "../Api/reservation";
import { useToastError } from "../hooks/useToast";
import OfferVirtualListContainer from "../components/OfferVirtualListContainer/OfferVirtualListContainer";
import "../components/DesktopOfferList/DesktopOfferList.css";


const HomePage = () => {
  const navigate = useNavigate();
  const dispatch= useAppDispatch()
  const showError = useToastError()
    const inscribedSubjects = useSelector(selectInscribedSubjects);

  const { sectionToChange, confirmChangeSectionData } = useSelector(selectFlags);

   const sectionToMantaine = (idMateria: number) => {
     return inscribedSubjects?.find((sub: IInscribedSubject) => sub.id === idMateria)?.title || "---";
   };
  
  const token = localStorage.getItem("token");
  
  useEffect(() => {

    if (!token) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }

  }, [])

   const handlTryChangeSection = async (idCurso: number) => {
     // setOpenModal(false);
     dispatch(addSubProcessSectionIds(idCurso));
     dispatch(setSectionToChange(null));

     const result = await changeSection({
       //@ts-ignore
       tic: confirmChangeSectionData.reserveData.tic,
       //@ts-ignore
       tim: confirmChangeSectionData.reserveData.tim,
       //@ts-ignore
       idC: confirmChangeSectionData.reserveData.idC,
       //@ts-ignore
       idA: confirmChangeSectionData.reserveData.idA,
       //@ts-ignore
       idS: confirmChangeSectionData.reserveData.idS,
     });

     if (result.data.responseCode !== 200) {
       showError(result.data.responseMessage);
       dispatch(removeSubProcessSectionIds(idCurso));
     }
     // finally
     dispatch(setConfirmChangeSectionData(null));
   };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
      {/* <DesktopOfferList /> */}
      <OfferVirtualListContainer/>
      {sectionToChange?.id && (
        <Modal
          open={true}
          onClose={() => dispatch(setSectionToChange(null))}
          message=""
          subMessage={`En caso de que el cambio NO sea posible, tu cupo original en ${sectionToMantaine(
            sectionToChange.idMateria
          )} será reservado. De esta forma, no perderás tu cupo para cursar la materia.`}
          topBorderWarn={false}
          closeButton={{
            label: "Cancelar",
            buttonFn: () => dispatch(setSectionToChange(null)),
            color: "#00C4B3",
          }}
          okButton={{
            label: "Continuar",
            buttonFn: async () => {
              await handlTryChangeSection(sectionToChange.id);
            },
            color: "#00C4B3",
          }}
        />
      )}
    </div>
  );
};

export default HomePage;
