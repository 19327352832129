import { findProfeTitular, parseHours } from "../../utils";
import { IOfferedSubjectData, IOfferedSubjectSection } from "../../types";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import "./ProfessorAndSubjectHours.css";
import { selectMobileFeatures } from "../../reduxSlices/globalFlagsSlice";
import { transformHorariosData } from "../../utils/professorAndSubject";
interface Props {
  subjectData: IOfferedSubjectSection["subjectData"];
  itemClass?: string;
  titleClass?: string;
  showBorderTop?: boolean;
  showRol?: boolean;
  allProfesVisible?: boolean;
  isDrawerContent: boolean;
}

function ProfessorAndSubjectHours({
  subjectData,
  showBorderTop = false,
  showRol = false,
  itemClass,
  allProfesVisible = true,
  isDrawerContent,
}: Props) {
  const student = useSelector(selectStudent);
  const mobileFeatures = useSelector(selectMobileFeatures);
  const result = transformHorariosData(subjectData.subjectsByType);

  const getProfesorLastname = (completeName: string) => {
    const lastname = completeName.split(",")[0];
    return lastname || completeName;
  };

  /* ------------------- different horarios in drawer panel ------------------- */
  if (isDrawerContent) {
    return (
      <>
        {mobileFeatures ? (
          <div className={`py-2 space-y-2 border-b-[1px] border-[#eeeeee]`}>
            <div className="space-y-1">
              {subjectData.subjectsByType &&
                transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
                  const renderProfesor = tipo.titulo === "Profesor";
                  const renderHorarios = !renderProfesor;
                  const mostrarHorarios = student.mostrarHorarios;
                  if (tipo.titulo === null) {
                    return (
                      <p key={Math.random() + index} className="font-semibold text-[#B7B7B7]">
                        ⚠ Información faltante.
                      </p>
                    );
                  } else if (renderHorarios && mostrarHorarios) {
                    return (
                      <div key={Math.random() + index} className="grid grid-cols-7 text-[14px]">
                        <h4 className="col-span-4 text-[#B7B7B7] text-[16px] pb-1">{tipo.titulo}</h4>
                        <h4 className="text-[#B7B7B7] text-[16px] pb-1 col-span-3">Aula</h4>

                        {tipo.titulo !== null &&
                          tipo.detalles.map((horario: any, ind: number) => (
                            <>
                              <p key={Math.random() + ind} className="col-span-4 pt-1">
                                {horario?.dia} {parseHours(horario.desde)}h a {parseHours(horario.hasta)}h
                              </p>
                              <p className="pt-1 truncate col-span-3">{horario.aula ? horario.aula : "Sin asignar"}</p>
                            </>
                          ))}
                      </div>
                    );
                  }
                })}
            </div>

            <div>
              <div className="bg-[#eeeeee] h-[1px] my-2"></div>
              {subjectData.subjectsByType &&
                transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
                  const renderProfesor = tipo.titulo === "Profesor";
                  if (renderProfesor) {
                    return (
                      <div key={Math.random() + index}>
                        {tipo.detalles.find(
                          (profe: any) => profe.rol && profe.rol !== null && profe.rol.toLowerCase() === "profesor"
                        ) && (
                          <>
                            <h4 className={`text-[16px] text-[#B7B7B7]`}>Profesor/a</h4>
                            {tipo.detalles.length > 0 ? (
                              tipo.detalles
                                .filter((profe: any) => profe.rol && profe?.rol.toLowerCase() === "profesor")
                                .sort((a: any, b: any) => {
                                  const nameA = a.nombre.toUpperCase();
                                  const nameB = b.nombre.toUpperCase();
                                  if (nameA < nameB) {
                                    return -1;
                                  }
                                  if (nameA > nameB) {
                                    return 1;
                                  }

                                  return 0;
                                })
                                .map((persona: any, i: number) => (
                                  <p key={Math.random() + i}>
                                    {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                                  </p>
                                ))
                            ) : (
                              <p>A designar</p>
                            )}
                          </>
                        )}
                        {tipo.detalles.find(
                          (profe: any) => profe.rol && profe?.rol.toLowerCase() === "profesor adjunto"
                        ) && (
                          <>
                            <h4 className={`text-[16px] text-[#B7B7B7] mt-2`}>Profesor/a adjunto</h4>
                            {tipo.detalles.length > 0 ? (
                              tipo.detalles
                                .filter(
                                  (profe: any) => profe.rol !== null && profe.rol.toLowerCase() === "profesor adjunto"
                                )
                                .sort((a: any, b: any) => {
                                  const nameA = a.nombre.toUpperCase();
                                  const nameB = b.nombre.toUpperCase();
                                  if (nameA < nameB) {
                                    return -1;
                                  }
                                  if (nameA > nameB) {
                                    return 1;
                                  }

                                  return 0;
                                })
                                .map((persona: any, i: number) => (
                                  <p key={Math.random() + i}>
                                    {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                                  </p>
                                ))
                            ) : (
                              <p>A designar</p>
                            )}
                          </>
                        )}
                        {tipo.detalles.find((profe: any) => profe.rol && profe?.rol.toLowerCase() === "auxiliar") && (
                          <>
                            <h4 className={`text-[16px] text-[#B7B7B7] mt-2`}>Auxiliar</h4>
                            {tipo.detalles.length > 0 ? (
                              tipo.detalles
                                .filter((profe: any) => profe.rol !== null && profe.rol.toLowerCase() === "auxiliar")
                                .sort((a: any, b: any) => {
                                  const nameA = a.nombre.toUpperCase();
                                  const nameB = b.nombre.toUpperCase();
                                  if (nameA < nameB) {
                                    return -1;
                                  }
                                  if (nameA > nameB) {
                                    return 1;
                                  }

                                  return 0;
                                })
                                .map((persona: any, i: number) => (
                                  <p key={Math.random() + i}>
                                    {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                                  </p>
                                ))
                            ) : (
                              <p>A designar</p>
                            )}
                          </>
                        )}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        ) : (
          <div className={`content-rows-ph row-ph`} id="horarios-in-panel-drawer">
            <div className="col-horarios">
              {subjectData.subjectsByType &&
                transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
                  const renderProfesor = tipo.titulo === "Profesor";
                  const renderHorarios = !renderProfesor;
                  const mostrarHorarios = student.mostrarHorarios;
                  if (tipo.titulo === null) {
                    return (
                      <p key={index} className="font-semibold text-[#B7B7B7]">
                        ⚠ Información faltante.
                      </p>
                    );
                  } else if (renderHorarios && mostrarHorarios) {
                    return (
                      <div className={`ph-horarios-inner-col ph-horarios-inner-col-mobile`} key={Math.random() + index}>
                        <div style={{ display: "flex" }}>
                          <h4 className="flex-grow">{tipo.titulo}</h4>
                          <h4 className="w-[90px] max-w-[90px]">Aula</h4>
                        </div>
                        {tipo.titulo !== null &&
                          tipo.detalles.map((horario: any, ind: number) => (
                            <div className="flex gap-1" key={horario + ind}>
                              <p key={Math.random() + ind} className="text-[14px] flex-grow text-nowrap">
                                {horario?.dia.slice(0, 2)} {parseHours(horario.desde)}h a {parseHours(horario.hasta)}h
                              </p>
                              <p title={horario.aula} className="text-[14px] w-[90px] max-w-[90px] truncate">
                                {horario.aula ? horario.aula : "Sin asignar"}
                              </p>
                            </div>
                          ))}
                      </div>
                    );
                  }
                })}
            </div>

            <div className="w-[1px] bg-[#eeeeee]"></div>

            <div className="col-profesores">
              {subjectData.subjectsByType &&
                transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
                  const renderProfesor = tipo.titulo === "Profesor";
                  if (renderProfesor) {
                    return (
                      <div className={`ph-horarios-inner-col ph-horarios-inner-col-mobile`} key={Math.random() + index}>
                        {tipo.detalles.find(
                          (profe: any) => profe.rol && profe.rol !== null && profe.rol.toLowerCase() === "profesor"
                        ) && (
                          <>
                            <h4 className={`panel-drawer-h4-title`}>Profesor/a</h4>
                            {tipo.detalles.length > 0 ? (
                              tipo.detalles
                                .filter((profe: any) => profe.rol && profe?.rol.toLowerCase() === "profesor")
                                .sort((a: any, b: any) => {
                                  const nameA = a.nombre.toUpperCase();
                                  const nameB = b.nombre.toUpperCase();
                                  if (nameA < nameB) {
                                    return -1;
                                  }
                                  if (nameA > nameB) {
                                    return 1;
                                  }

                                  return 0;
                                })
                                .map((persona: any, i: number) => (
                                  <p key={Math.random() + i}>
                                    {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                                  </p>
                                ))
                            ) : (
                              <p>A designar</p>
                            )}
                          </>
                        )}
                        {tipo.detalles.find(
                          (profe: any) => profe.rol && profe?.rol.toLowerCase() === "profesor adjunto"
                        ) && (
                          <>
                            <h4 className={`panel-drawer-h4-title mt-2`}>Profesor/a adjunto</h4>
                            {tipo.detalles.length > 0 ? (
                              tipo.detalles
                                .filter(
                                  (profe: any) => profe.rol !== null && profe.rol.toLowerCase() === "profesor adjunto"
                                )
                                .sort((a: any, b: any) => {
                                  const nameA = a.nombre.toUpperCase();
                                  const nameB = b.nombre.toUpperCase();
                                  if (nameA < nameB) {
                                    return -1;
                                  }
                                  if (nameA > nameB) {
                                    return 1;
                                  }

                                  return 0;
                                })
                                .map((persona: any, i: number) => (
                                  <p key={Math.random() + i}>
                                    {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                                  </p>
                                ))
                            ) : (
                              <p>A designar</p>
                            )}
                          </>
                        )}
                        {tipo.detalles.find((profe: any) => profe.rol && profe?.rol.toLowerCase() === "auxiliar") && (
                          <>
                            <h4 className={`panel-drawer-h4-title mt-2`}>Auxiliar</h4>
                            {tipo.detalles.length > 0 ? (
                              tipo.detalles
                                .filter((profe: any) => profe.rol !== null && profe.rol.toLowerCase() === "auxiliar")
                                .sort((a: any, b: any) => {
                                  const nameA = a.nombre.toUpperCase();
                                  const nameB = b.nombre.toUpperCase();
                                  if (nameA < nameB) {
                                    return -1;
                                  }
                                  if (nameA > nameB) {
                                    return 1;
                                  }

                                  return 0;
                                })
                                .map((persona: any, i: number) => (
                                  <p key={Math.random() + i}>
                                    {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                                  </p>
                                ))
                            ) : (
                              <p>A designar</p>
                            )}
                          </>
                        )}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        )}
      </>
    );
  }

  /* ---------------------------- content for card ---------------------------- */
  return (
    <>
      {mobileFeatures ? (
        <div style={{ zIndex: 1 }} className={`w-[75%] space-y-2 h-full py-3`}>
          {subjectData.subjectsByType &&
            transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
              const renderHorarios = !(tipo.titulo === "Profesor");
              const mostrarHorarios = student.mostrarHorarios;

              if (tipo.titulo === null) {
                return <p className="font-semibold text-[#B7B7B7]">⚠ Información faltante.</p>;
              } else if (renderHorarios && mostrarHorarios && renderHorarios !== null) {
                return (
                  <div className={`text-[14px] flex`} key={Math.random() + index}>
                    <h4 className={`text-[#B7B7B7] w-[35%]`}>{tipo.titulo}</h4>
                    <div className="w-[65%]">
                      {tipo.titulo !== null &&
                        tipo.detalles.map((horario: any, ind: number) => (
                          <p key={Math.random() + ind}>
                            {horario?.dia.slice(0, 2)} {parseHours(horario.desde)}h a {parseHours(horario.hasta)}h
                          </p>
                        ))}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      ) : (
        <div
          style={{ zIndex: 1 }}
          className={`content-rows-ph mobile-horarios row-ph ${showBorderTop ? "border-top-ph" : ""}`}
        >
          {subjectData.subjectsByType &&
            transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
              const renderProfesor = tipo.titulo === "Profesor";
              const renderHorarios = !renderProfesor;
              const mostrarHorarios = student.mostrarHorarios;

              if (tipo.titulo === null) {
                return <p className="font-semibold text-[#B7B7B7]">⚠ Información faltante.</p>;
              } else if (renderProfesor) {
                return (
                  <div
                    className={`ph-horarios-inner-col profesores-first-col hide-profesores-mobile`}
                    key={Math.random() + index}
                  >
                    <h4 className={`ph-h4-title`}>{tipo.titulo === "Profesor" ? "Profesor/a" : tipo.titulo}</h4>
                    <p>{findProfeTitular(subjectData)}</p>
                  </div>
                );
              } else if (renderHorarios && mostrarHorarios && renderHorarios !== null) {
                return (
                  <div className={`ph-horarios-inner-col`} key={Math.random() + index}>
                    <h4 className={`ph-h4-title`}>{tipo.titulo}</h4>
                    <div className="horarios-col-mobile">
                      {tipo.titulo !== null &&
                        tipo.detalles.map((horario: any, ind: number) => (
                          <p className="text-nowrap truncate overflow-ellipsis" key={Math.random() + ind}>
                            {horario?.dia.slice(0, 2)} {parseHours(horario.desde)}h a {parseHours(horario.hasta)}h
                          </p>
                        ))}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      )}
    </>
  );
}

export default ProfessorAndSubjectHours;
