import "./PanelDrawer.css";
import "animate.css";
import { useAppDispatch } from "../../app/store";
import {
  selectCalendarSubjectsList,
  selectInscribedSubjects,
  selectPinnedSubjectsList,
  selectSubjectsQuotas,
  setDetailSubjectOffered,
} from "../../reduxSlices/subjectsSlice";
import { IOfferedSubjectData, IOfferedSubjectSection } from "../../types";
import { useSelector } from "react-redux";
import { selectStudent } from "../../reduxSlices/studentSlice";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import ProfessorAndSubjectHours from "../ProfessorAndSubjectHours/ProfessorAndSubjectHours";
import QuotaCountIcon from "../QuotaCountIcon/QuotaCountIcon";
import ButtonInscription from "../ButtonInscription/ButtonInscription";
import { selectMobileFeatures } from "../../reduxSlices/globalFlagsSlice";
import { useNavigate } from "react-router-dom";
import ButtonCalendarize from "../ButtonCalendarize/ButtonCalendarize";
import ButtonPin from "../ButtonPin/ButtonPin";
import { findProfeTitular } from "../../utils";
import { getCourseQuota } from "../../utils/quota";
import SlCopyButton from "@shoelace-style/shoelace/dist/react/copy-button";

interface IPropsPanelDrawer {
  subject: IOfferedSubjectSection;
}

const PanelDrawer = ({ subject }: IPropsPanelDrawer) => {
  const dispatch = useAppDispatch();
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const { prioridad } = useSelector(selectStudent);
  const mobileFeatures = useSelector(selectMobileFeatures);

  const showCupoInfo =
    process.env.REACT_APP_ENV === "Test" ||
    process.env.REACT_APP_ENV === "Localhost" ||
    process.env.REACT_APP_ENV === "Staging" ||
    process.env.REACT_APP_ENV === "Dev";

  const esInscripta = inscribedSubjects?.filter((curso) => curso.idCurso === subject.id).length ? true : false;
  const quota = getCourseQuota(subjectsQuotas, subject.id);
  const quotaQty = quota.qty;

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleClose = () => {
    dispatch(setDetailSubjectOffered(null));
  };

  const tagsListingRender = (tags: string) => {
    const colors = ["#C47DDD", "#2277DA", "#209DAE", "#97D86C", "#FBC54F", "#FF7A30", "#FF7A30"];
    const list = tags.split(",").map((item) => item.trim());

    return list.map((tag, i) => (
      <p key={tag + i} style={{ background: colors[i < colors.length ? i : colors.length % i] }}>
        {tag}
      </p>
    ));
  };

  return (
    <>
      {!mobileFeatures && (
        <div className="absolute w-full h-screen top-0 left-0 overflow-hidden z-[60]" onClick={handleClose}></div>
      )}
      <div id="panel-drawer" className="desktop-panel-drawer animate__animated animate__slideInRight animate__faster">
        {/* ------------------------------ PANEL CONTENT ----------------------------- */}
        <div className="desktop-panel-drawer-content">
          {esInscripta && <h5 className="confirmed-subject-title">INSCRIPCIÓN CONFIRMADA</h5>}
          <div className="panel-drawer-description-title mr-[30px]">
            {prioridad && !esInscripta && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <QuotaCountIcon
                  qty={quotaQty}
                  subjectData={subject.subjectData}
                  availability={quota.availability}
                  idMateria={subject.idMateria}
                  quota={quota}
                />
              </div>
            )}
            <div className="panel-drawer-titles">
              <h2
                className="panel-drawer-title-name"
                title={
                  subject.subjectData.reserveData.tic && subject.subjectData.reserveData.tim && showCupoInfo
                    ? "Cupo: " +
                      String(subject.subjectData.reserveData.tic + " " + subject.subjectData.reserveData.tim) +
                      "   |   Cuota: " +
                      String(getCourseQuota(subjectsQuotas, subject.id).qty || "-") +
                      `   |   idCurso:  ${subject.id}` +
                      `   |   idMateria:  ${subject.idMateria}` +
                      `   |   idCupo:  ${subject.subjectData.reserveData.idC}`
                    : ""
                }
              >
                {subject.section}
                <span>
                  <SlCopyButton
                  style={{padding:"0px", fontSize:"14px"}}
                    className="p-0 m-0"
                    value={subject.section}
                    copy-label="Copiar"
                    success-label="Copiado"
                    error-label="Ups, no se pudo copiar"
                  />
                </span>
              </h2>
              <p className="panel-drawer-title-profesor">{findProfeTitular(subject.subjectData)}</p>
            </div>
            <SlIcon
              name="x-lg"
              style={{ color: "black", minWidth: "20px", height: "20px", cursor: "pointer" }}
              onClick={handleClose}
            ></SlIcon>
          </div>
          <div className="drawe-content-scroll pr-[30px]">
            <ProfessorAndSubjectHours showBorderTop showRol subjectData={subject.subjectData} isDrawerContent={true} />

            {subject.condicionAprob && (
              <div className="panel-drawer-content-rows">
                <h3 className="panel-drawer-h3-title">Condiciones de aprobación del Curso</h3>
                <p>{subject.condicionAprob}</p>
              </div>
            )}

            {(subject.year || subject.creditos || subject.modalidad) && (
              <div className="panel-drawer-content-rows row-modalidad">
                {subject.year && (
                  <div className="modalidad-inner-col">
                    <h4>Año</h4>
                    <p>{subject.year}</p>
                  </div>
                )}
                {subject.creditos > 0 && (
                  <div className="modalidad-inner-col">
                    <h4>Créditos</h4>
                    <p>{subject.creditos}</p>
                  </div>
                )}
                {subject.modalidad && (
                  <div className="modalidad-inner-col">
                    <h4>Modalidad</h4>
                    <p>{subject.modalidad}</p>
                  </div>
                )}
              </div>
            )}

            {subject.tag && (
              <div className="panel-drawer-content-rows">
                <h4>Campo menor</h4>
                <div className="panel-tags-list-container">{tagsListingRender(subject.tag)}</div>
              </div>
            )}

            {subject.obs && (
              <div className="panel-drawer-content-rows">
                <h4>Observaciones</h4>
                <p>{subject.obs}</p>
              </div>
            )}

            {subject.catalogo && (
              <div className="panel-drawer-content-rows">
                <h3 className="panel-drawer-h3-title">Descripción del curso</h3>
                <p className="panel-drawer-description-box">{subject.catalogo}</p>
                {subject.link && (
                  <a className="panel-drawer-link" href={subject.link} target="_blank">
                    Ver más contenido sobre el curso
                  </a>
                )}
              </div>
            )}
          </div>

          {!esInscripta && (
            <div id="row-actions">
              <div className="panel-actions-row mr-[30px]">
                <div className="panel-drawer-circle-buttons">
                  <ButtonPin subject={subject} />
                  <ButtonCalendarize subject={subject} />
                </div>
                <ButtonInscription id={subject.id} idMateria={subject.idMateria} subjectData={subject.subjectData} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PanelDrawer;
